import React from "react"
import { DefaultLayout } from "../layouts/DefaultLayout"
import { localizedPage } from "../i18n/Localized"
import appPreviewGif from "../data/preview.gif"
import { IPhoneXFrame } from "../components/DeviceFrame"
import { FullWidthComponent } from "../components/FullWidthComponent"
import { sizes } from "../layouts/sizes"
import styled from "styled-components"
import { colors } from "../layouts/colors"
import Color from "color"
import { AppStoreBadge, PlayStoreBadge } from "../components/appStoreBadges"
import { Trans } from "react-i18next"
import { useState } from "react"
import { useEffect } from "react"

const Title = styled("div")`
  margin-top: ${sizes.margins.large}px;
  margin-bottom: ${sizes.margins.large}px;
  font-size: 1.5em;
  text-align: center;
`
const Title2 = styled(Title)`
  font-weight: 600;
  margin-top: ${sizes.margins.large * 2}px;
  margin-bottom: ${sizes.margins.large}px;
`

const ButtonBase = styled("a")`
  display: block;
  margin: ${sizes.margins.large}px;
  font-size: 1.2em;
  text-align: center;
  padding: ${sizes.margins.normal}px;
  border-radius: ${sizes.margins.normal}px;
  transition: 0.5s;
  :hover {
    text-decoration: none;
  }
  :active {
    transition: 0s;
  }
`

const Button = styled(ButtonBase)`
  border-width: 0.25em;
  border-color: ${colors.highlight.normal};
  color: ${colors.highlight.normal};
  border-style: solid;
  box-sizing: border-box;
  font-weight: normal;
  flex: 1;
  cursor: pointer;
  :hover {
    border-color: ${new Color(colors.highlight.normal).darken(0.1).toString()};
    color: ${new Color(colors.highlight.normal).darken(0.1).toString()};
  }
  :active {
    border-color: ${new Color(colors.highlight.normal).darken(0.5).toString()};
    color: ${new Color(colors.highlight.normal).darken(0.5).toString()};
  }
`

const PrimaryButton = styled(ButtonBase)`
  background-color: ${colors.highlight.normal};
  color: ${colors.neutral.veryLight};
  font-weight: bold;
  cursor: pointer;
  :hover {
    color: ${colors.neutral.veryLight};
    background-color: ${new Color(colors.highlight.normal)
      .darken(0.1)
      .toString()};
  }
  :active {
    color: ${colors.neutral.veryLight};
    background-color: ${new Color(colors.highlight.normal)
      .darken(0.5)
      .toString()};
  }
`

const CenteredPadded = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${sizes.margins.normal}px;
`

export default localizedPage(() => {
  const [appOnly, setAppOnly] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    setAppOnly(params.has("app-only"))
  }, [])

  const openInMaphi = () => {
    const params = new URLSearchParams(document.location.search)
    window.open(`maphi://share?${params.toString()}`)
  }

  const openInEngine = () => {
    const params = new URLSearchParams(document.location.search)
    window.open(`https://engine.maphi.app?${params.toString()}`)
  }

  return (
    <DefaultLayout
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <FullWidthComponent
        style={{ flex: 1 }}
        centerStyle={{ margin: sizes.margins.normal, width: "100%" }}
      >
        <Title>
          <Trans i18nKey="share.title">
            A formula has been shared with you!
          </Trans>
        </Title>
        <PrimaryButton onClick={openInMaphi}>
          <Trans i18nKey="share.open-in-maphi">Open in Maphi</Trans>
        </PrimaryButton>
        {!appOnly && (
          <Button onClick={openInEngine}>
            <Trans i18nKey="share.open-preview">Preview online</Trans>
          </Button>
        )}
        <Title2>
          <Trans i18nKey="site.title">Maphi - The Math App</Trans>
        </Title2>
        <Title>
          <Trans i18nKey="landing.cta.title">Maphi - The Math App</Trans>
        </Title>
        <CenteredPadded>
          <IPhoneXFrame style={{ position: "relative" }}>
            {style => (
              <img
                style={style}
                src={appPreviewGif}
                alt="Solve an equation through drag-and-drop gestures"
              />
            )}
          </IPhoneXFrame>
        </CenteredPadded>
        <CenteredPadded>
          <a href="https://apps.apple.com/us/app/maphi-the-math-app/id1276181580">
            <AppStoreBadge
              style={{
                margin: sizes.margins.normal,
                height: 50,
              }}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.edutapps.maphi">
            <PlayStoreBadge
              style={{
                margin: sizes.margins.normal,
                height: 50,
              }}
            />
          </a>
        </CenteredPadded>
      </FullWidthComponent>
    </DefaultLayout>
  )
})
