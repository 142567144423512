import * as React from "react"

import "./index.css"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { useTranslation } from "react-i18next"
import { MaphiHelmet } from "../components/MaphiHelmet"

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  style?: React.CSSProperties
  title?: string
}

export const DefaultLayout = (props: DefaultLayoutProps) => {
  const { t, i18n } = useTranslation()

  return (
    <div style={props.style}>
      <MaphiHelmet title={props.title} />
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}
